import { getCookie, setCookie } from '@bit/gerenciatd.utils.common';
import { findOutEnv } from '@bit/gerenciatd.utils.environment';
import FrontSonda, { SEVERITY_NAME } from '@bit/gerenciatd.utils.front-sonda';
import { PROMOTE_LOGIN_COOKIE, SPEECH_BUBBLE_SESSION } from './Constants';

export const SEVERITY_NAME_TOOL = SEVERITY_NAME;

const HOME_URLS = [
  'https://www.movistar.es',
  'https://www.movistar.es/',
  'https://www.movistar.es/particulares/',
  'https://www.movistar.es/particulares',
  'https://col10mgmt.tsm.inet/',
  'https://col10mgmt.tsm.inet',
  'http://127.0.0.1:8089/',
]

export const isLocalHost = () => {
  let res = false;
  try {
    res = window.location.href.indexOf('127.0.0.1') > -1;
  } catch (err) {
    return res;
  }
  return res;
}

export const isQaEnv = () => {
  let res = false;
  try {
    res = window.location.href.indexOf('movistar.es.cert') > -1
    || window.location.href.indexOf('qa.cms.col.movistar.es') > -1
    || window.location.href.indexOf('col10mgmt.tsm.inet') > -1
    return res;
  } catch (err) {
    return res;
  }
}

export const getPromoteLoginState = ({ pageSegment, contextURL }) => {
  const isParticulares = pageSegment === 'particulares';

  if (!isParticulares) {
    return {
      showModal: false,
      showSpeechBubble: false,
    }
  }

  const isHome = HOME_URLS.find(url => url === contextURL);

  const promoteLoginCookieValue = getCookie(PROMOTE_LOGIN_COOKIE);
  let speechBubbleShowed = getCookie(SPEECH_BUBBLE_SESSION);
  if (speechBubbleShowed === '') {
    speechBubbleShowed = false;
  }

  const actualDate = Date.now();
  /* Si no existe la cookie promocionamos */
  if (!promoteLoginCookieValue && !!isHome && !speechBubbleShowed) {
    return {
      showModal: true,
      showSpeechBubble: false,
    }
  }

  const difference = actualDate - promoteLoginCookieValue;
  const days = parseInt(difference / (1000 * 3600 * 24), 10);

  if (days <= 30) {
    return {
      showModal: false,
      showSpeechBubble: !speechBubbleShowed,
    }
  }

  return {
    showModal: false,
    showSpeechBubble: !speechBubbleShowed,
  }
}

export const setPromoteLoginModal2False = ({ showModal, showSpeechBubble }) => {
  if (!showModal) {
    setCookie(PROMOTE_LOGIN_COOKIE, Date.now(), 30);
  }
  if (!showSpeechBubble) {
    setCookie(SPEECH_BUBBLE_SESSION, true);
  }
};

/**
 * Comprueba si existe tabId
 * @returns {boolean}
 */
const existsTabId = () => {
  try {
    if (window.sessionStorage) {
      return sessionStorage.getItem('mov_tabID') !== null;
    }
    return null;
  } catch (err) {
    return null;
  }
};

/**
 * Genera un id para la pestaña actual del navegador.
 * @returns {number}
 */
const generateTabId = () => {
  const tabId = Date.now(); // Todo
  try {
    if (window.sessionStorage) {
      sessionStorage.setItem('mov_tabID', tabId);
      return sessionStorage.getItem('mov_tabID');
    }
    return `notSupportedSessionStorage-${tabId}`;
  } catch (err) {
    return `notSupportedSessionStorage-${tabId}`;
  }
};

/**
 * Obtiene el tabId de la pestaña actual.
 * @returns {func}
 */
export const getTabId = () => {
  if (existsTabId()) {
    return sessionStorage.getItem('mov_tabID');
  }
  return generateTabId();
};

/**
 * Returns page segment
 * @return {string} 'particulares' by default
 */
export const checkSegment = () => {
  let res = '';

  // intentamos por url empresas
  const { pathname } = window.location;
  if (pathname.indexOf('/empresas') === 0) {
    res = 'empresas';
  }

  // intentamos por url particulares
  if (
    pathname.indexOf('/particulares') === 0
    || pathname.indexOf('/cliente/areaprivada') === 0
  ) {
    res = 'particulares';
  }

  const cookieNav = getCookie('SEG_NAV');
  if (!res && cookieNav && cookieNav !== '') {
    res = cookieNav;
  }
  // en caso de no detectar por url, y en caso de que la cookie venga vacía
  if (!res) {
    res = 'particulares';
  }

  return res;
};
/**
 * Comprueba si hay alguna clave de keys en el objecto que se ha pasado
 * para actualizar objectToCompare
 * @param {Object} objectToSearch objeto donde buscar las claves.
 * @param {*} keys claves que nos importan estas dependerán de la cola en la que estemos
 * @param {*} objectToCompare estructura completa que tenemos en context
 * @returns {boolean}
 */
export const isThereAnyKeyWithChanges = (
  objectToSearch,
  keys,
  objectToCompare
) => {
  let result = false;
  for (let i=0; i<keys.length; i += 1) {
    let keyToSearch = keys[i];
    if (keyToSearch.indexOf('.') >= 0) {
      [keyToSearch] = keyToSearch.split('.');
    }
    if (objectToSearch[keyToSearch]
       && objectToSearch[keyToSearch] !== objectToCompare[keyToSearch]) {
      result = true;
    }
  }
  return result;
};

export const createBasicContext = () => {
  const environment = findOutEnv();
  const context = {
    environment,
  };

  return context;
};

/**
 * Encapsula el envío de errores al sistema de sondas.
 * @param {String} processName Nombre del método que monitoriza
 * @param {String} severidad grado de severidad del mensaje
 * @param {String} info info extra a enviar a sonda
 * @param {Error} error información del error
 * @returns {void}
 */
export const sendMessageToSondaTool = ({
  context = createBasicContext(),
  processName,
  severidad,
  info,
  error,
}) => {
  const sonda = new FrontSonda({
    processName,
    filePath: 'modules/context',
    context,
  });

  switch (severidad) {
    case SEVERITY_NAME.INFO:
      sonda.infoMessage(info);
      break;
    case SEVERITY_NAME.WARNING:
      sonda.warningMessage({ info, error });
      break;
    case SEVERITY_NAME.ERROR:
      sonda.errorMessage({ info, error });
      break;
    case SEVERITY_NAME.SEVERAL:
      sonda.severalMessage({ info, error });
      break;
    case SEVERITY_NAME.FATAL:
    default:
      sonda.fatalMessage({ info, error });
      break;
  }
};

/**
 * Obtiene la info de hashes de cliente desglosada
 * @param {object} ip3gResponse
 * @returns {object}
 */
export const composeI3Object = (ip3gResponse) => {
  const i3DssObj = ip3gResponse || {};
  /* OTROS QUE NO INTERPRETAMOS DE MOMENTO */

  // const i3D = i3DssObj.d ? i3DssObj.d : '';
  // const i3F = i3DssObj.f ? i3DssObj.f : '';
  // const i3I = i3DssObj.i ? i3DssObj.i : '';
  // const i3K = i3DssObj.k ? i3DssObj.k : '';
  // const i3M = i3DssObj.m ? i3DssObj.m : '';
  // const i3O = i3DssObj.o ? i3DssObj.o : '';

  /* HASHES QUE INTERPRETAMOS */
  const i3A = i3DssObj.a ? i3DssObj.a : '';
  const i3I3I = i3DssObj.i3i ? i3DssObj.i3i : ''; // EL ADICIONAL
  const i3X = i3DssObj.x ? i3DssObj.x : '';
  const i3Z = i3DssObj.z ? i3DssObj.z : '';
  const i3C = i3DssObj.c ? i3DssObj.c : '';
  const i3L = i3DssObj.l ? i3DssObj.l : '';
  const i3P = i3DssObj.p ? i3DssObj.p : '';
  const i3T = i3DssObj.t ? i3DssObj.t : '';
  const i3G = i3DssObj.g ? i3DssObj.g : '';

  /* MAPEO A CONTEXT */
  return {
    userCUCFijoHash: i3C,
    userCUCMovilHash: i3L,
    userCUCPrepagoHash: i3P,
    userIPHash: i3A,
    userOperadorHash: i3X,
    userGl: i3I3I,
    userTelefonoFijoHash: i3T,
    userTelefonoMovilHash: i3G,
    userIdPartyHash: i3Z,
    userClient: !!(i3C || i3L || i3P || i3A || i3T || i3G),
  };
};

/**
 * Combina los valores del ip3g entre las respuestas de servicio y los de IC
 * @param {object} IcHashes
 * @param {object} serviceHashes
 * @returns {object}
 */
export const mergeIp3gHashes = (IcHashes, serviceHashes) => {
  const result = { ...serviceHashes };
  const keys = Object.keys(IcHashes);
  keys.forEach((keyName) => {
    if (IcHashes[keyName]) {
      result[keyName] = IcHashes[keyName];
    }
  });
  return result;
};

/**
 * Transforma el string de xcol a ip3hashes
 * @param {string} IcHashes
 * @returns {object}
 */
export const tranformXcol2Ip3Hashes = (xcol) => {
  let res = xcol;
  res = window.atob(res.split('.')[1]);
  res = JSON.parse(res);
  res = composeI3Object(res);
  return res;
};

/**
 * Deduce si tiene cookie de gestor
 * @param {object} IcHashes
 * @param {object} serviceHashes
 * @returns {object}
 */
export const isGestor = () => {
  let res = false;
  try {
    if (getCookie('TGES_LOGGED')) {
      res = true;
    }
    return res;
  } catch (err) {
    return res;
  }
};

/**
 * Calcula valores relacionados con la velocidad de conectividad
 * @returns {object}
 * {
 *  delay: int => tiempo para que aparezca las ondas grises
 *  timeOut: int => tiempo para que se muestre pantalla error de conexión
 * }
 */
export const calculateNetworkParams = () => {
  let delay = 1000;
  let timeout = 5000;
  let type = '4g';
  try {
    const connection = navigator.connection
      || navigator.mozConnection
      || navigator.webkitConnection;
    type = connection.effectiveType;
    switch (type) {
      case 'slow-2g':
        timeout = 20000;
        delay = 10000;
        break;
      case '2g':
        timeout = 18000;
        delay = 9000;
        break;
      case '3g':
        timeout = 15000;
        delay = 7500;
        break;
      case '4g':
      default:
        timeout = 10000;
        delay = 5000;
        break;
    }
  } catch (err) {
    return { timeout, delay, type }
  }
  return { timeout, delay, type }
};

/**
 * Obtiene la variable 'mockedContext' del sessionStorage
 * @returns {object} Objeto con los valores de mockedContext (null si no existe)
 */
export const getMockedContext = (envName = 'prod') => {
  if (envName !== 'prod') {
    try {
      const mockString = sessionStorage.getItem('mockedContext');
      return JSON.parse(mockString);
    } catch (err) {
      return null;
    }
  }
  return null;
};

export const getMsieVersion = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  return (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./));
}

export const redirectIE2NotSuported = () => {
  let rightPath = false;
  const movistarHost = window.location.host.indexOf('movistar.es') > -1;
  const firstPathToken = window.location.pathname.split('/')[1];
  const IE_PATHS = ['particulares', 'empresas', 'autonomos', ''];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < IE_PATHS.length; i++) {
    if (!rightPath && firstPathToken === IE_PATHS[i]) {
      rightPath = true;
    }
  }

  if (movistarHost && getMsieVersion() && rightPath) {
    window.location.pathname = '/navegador-no-soportado'
  }
}

export const ALLOWED_TCOLAPI_HOST = [
  'col10mgmt.tsm.inet',
  'movistar.es',
  'www.movistar.es',
  'movistar.cert',
  'www.movistar.cert',
]

export const checkTcolApiAllowed = () => {
  let res = false;
  if (window && window.location && window.location.host) {
    const { host } = window.location;
    // 1) si contiene cms.col
    if ((host.indexOf('.cms.col') > -1)) {
      res = true;
    }
    // 2) si es algo de los hosts
    if (!res && ALLOWED_TCOLAPI_HOST.includes(host)) {
      res = true;
    }
  }

  return res;
}
