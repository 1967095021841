/* eslint-disable import/no-extraneous-dependencies */
import { processName } from '@bit/gerenciatd.utils.ada-byron';
import Request, { getMockedInfo } from '@bit/gerenciatd.utils.request';
import Sonda from '@bit/gerenciatd.utils.sonda';
import { MethodType } from '@bit/gerenciatd.utils.types';
import { createBasicContext } from '../Utils'
import { normalizeInfoContexto, normalizeUserName, DEFAULT_RESPONSE } from './normalizer/index';

export const DEFAULT_RESPONSE_ON_ERROR = DEFAULT_RESPONSE;

const PATHS = {
  GET_INFO_CONTEXT: '/mimovistar-cliente/publico/api/status/infocontexto',
}

export const getInfoContext = (
  context = createBasicContext(),
) => {
  const sonda = new Sonda({
    eventName: 'CCLI.GETINFOCONTEXT',
    context,
    origin: processName,
  });

  const uniqueUID = `uniqueUID=CT${Date.now()}`;

  const { legadoNoVCM: connectionData, name } = context.environment;
  const { endpoint: host } = connectionData;
  const URL_API = `${host}${PATHS.GET_INFO_CONTEXT}?${uniqueUID}`;

  const mockInfo = getMockedInfo('getInfoContext', name);

  try {
    const request = new Request({
      method: MethodType.GET,
      endpoint: URL_API,
      onSuccess: (response) => {
        let dataNormalized = null;
        // try catch aki para que el flujo siga igual
        dataNormalized = normalizeInfoContexto(response);
        return dataNormalized;
      },
      sonda,
      headers: {
        withCredentials: true,
        timeout: 2000,
      },
      mockInfo,
    });

    return request.makeRequest();
  } catch (err) {
    sonda.errorJs(err);
    return null;
  }
};


export { normalizeUserName };
