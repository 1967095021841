import {
  composeI3Object,
  sendMessageToSondaTool,
  SEVERITY_NAME_TOOL as SEVERITY_NAME,
} from '../../Utils';


// recibe el contexto y la funcion para actualizar
export async function getI3Info({ context }) {
  return new Promise((resolve) => {
    window.IC.initServiceI3(
      (info) => {
        const newHashes = composeI3Object(info);
        resolve({ ...newHashes });
      },
      (err) => {
        resolve(composeI3Object({}));
        sendMessageToSondaTool({
          processName: 'getI3Info',
          severidad: SEVERITY_NAME.ERROR,
          info: null,
          error: err,
          context,
        });
      },
    );
  })

}
