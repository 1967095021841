import FrontSonda from '@bit/gerenciatd.utils.front-sonda';
import { getCookie } from '@bit/gerenciatd.utils.common';
import { findOutEnv } from '@bit/gerenciatd.utils.context';

export const isTelcoSite = () => {
  return window.location.href.indexOf('cliente/areaprivada/#') >= 0;
}

export const isAplicacionesTelco = () => {
  if (window.location.href.indexOf('/particulares/Aplicaciones/Telco/procesoCompra/baf') >= 0) {
    return false;
  }

  if (window.location.href.indexOf('/particulares/Aplicaciones/Telco/procesoCompra/tarifaMovil') >= 0) {
    return false;
  }

  if (window.location.href.indexOf('/particulares/Aplicaciones/Telco/procesoCompra/tecuida') >= 0) {
    return false;
  }

  if (window.location.href.indexOf('/particulares/Aplicaciones/Telco/procesoCompra/internet') >= 0) {
    return false;
  }

  if (window.location.href.indexOf('/particulares/Aplicaciones/Telco/procesoCompra/formMan/') >= 0) {
    return false;
  }

  if (window.location.href.indexOf('/particulares/Aplicaciones/Telco/procesoCompra/miMovistar') >= 0) {
    return false;
  }
  
  if (window.location.href.indexOf('/particulares/Aplicaciones/Telco/procesoCompra/gamepass') >= 0) {
    return false;
  }

  if (window.location.href.indexOf('/particulares/Aplicaciones/Telco/procesoCompra/dispositivos') >= 0) {
    return false;
  }

  return window.location.href.indexOf('particulares/Aplicaciones/Telco/procesoCompra') >= 0;
}

export const DEFAULT_RESPONSE = {
  contextVars: {
    userName: '',
    userPriority: false,
    userSecondaryType: '',
    userType: '', // este tiene que se estrictamente para el usuario
    businessName: '',
    userSegment: '',
    loggedById: false,
  },
  xCol4Hashes: '',
}

const TIPO = {
  TELCO: 'telco',
  LEGADO_MOVIL: 'legadomovil',
  LEGADO_FIJO: 'legadofijo',
  LEGADO_CONVERGENTE: 'legadoconvergente',
}

export const createBasicContext = () => {
  const environment = findOutEnv();
  const context = {
    environment,
  };

  return context;
};

const decideUserType = (logado, tipo) => {
  let res = ''; // significa no logado en contexto
  const tipoNorm = tipo ? tipo.toLowerCase() : '';
  // Interpretando valores del servicio
  if (logado && tipoNorm) {
    if (tipoNorm.indexOf(TIPO.TELCO) > -1) {
      res = 'Telco';
    }
    if (
      tipoNorm.indexOf(TIPO.LEGADO_MOVIL) > -1
      || tipoNorm.indexOf(TIPO.LEGADO_FIJO) > -1
      || tipoNorm.indexOf(TIPO.LEGADO_CONVERGENTE) > -1) {
      res = 'Legado';
    }
  }
  // Interpretando Urls
  if (isTelcoSite() || isAplicacionesTelco()) {
    res = 'Telco';
  }


  if (getCookie('TGES_LOGGED')) {
    res = 'Gestor';
  }

  return res;
}


const normalizeError = (rawData, erroresFlag) => {
  const erroresArray = erroresFlag ? JSON.stringify(rawData.errores) : '';
  // SEND TO front sonda with all errors
  const sonda = new FrontSonda({
    processName: 'getInfoContextFromCCLI',
    filePath: 'modules/ccli/normalizer',
    context: createBasicContext(),
  });
  sonda.warningMessage({ info: erroresArray });
  return DEFAULT_RESPONSE;
}


export const normalizeUserName = (nombre, apellidos) => {
  let userName = '';
  if (nombre) {
    userName = `${nombre}`;
    if (apellidos) {
      userName = `${userName} ${apellidos}`;
    }
  }


  return userName;
}

export const normalizeBoolean = (boolData) => {
  let res = false;
  if (boolData) {
    res = true;
  }
  return res;
}

export const normalizeResponseOk = (response) => {
  try {
    const { infocontexto } = response.datos;
    const {
      logado,
      tipo,
      nombre,
      apellidos,
      priority,
      secundario,
      razonSocial,
      segmento,
      auth,
      tpoIdentificador,
      xcol,
    } = infocontexto;

    const userType = decideUserType(logado, tipo);

    const contextVars = {
      userName: normalizeUserName(nombre, apellidos),
      userPriority: normalizeBoolean(priority),
      userSecondaryType: tipo || '',
      userType,
      businessName: razonSocial || '',
      userSegment: segmento || '',
      loggedById: !normalizeBoolean(secundario),
      userAuth: auth || '',
      tipoIdentificador: tpoIdentificador || '',
    }
    const xCol4Hashes = xcol || '';
    // const userSegment =
    return {
      xCol4Hashes,
      contextVars,
    }
  } catch (error) {
    // TODO SONDAR
    const sonda = new FrontSonda({
      processName: 'normalizeResponseOk',
      filePath: 'modules/ccli/normalizer',
      context: createBasicContext(),
    });
    sonda.warningMessage({ info: null, error });
    return DEFAULT_RESPONSE;
  }
}

export const normalizeInfoContexto = (rawData) => {
  let result = DEFAULT_RESPONSE;
  // si tiene campo response
  if (rawData && rawData.response) {
    let { response } = rawData;
    response = JSON.parse(response);
    const errores = (response.errores && Array.isArray(response.errores) && response.errores.length === 0);
    const isOk = ((response && response.data)
      || response.exito
      || (response.errores && Array.isArray(response.errores) && response.errores.length === 0));

    if (!isOk) {
      return normalizeError(response, errores);
    }
    result = normalizeResponseOk(response);
  }

  return result;
};
