import { QUEUE_NAMES } from '@bit/gerenciatd.utils.context';
import { PROPS_ALLOW_EDIT } from '../Constants';
import { sendMessageToSondaTool, setPromoteLoginModal2False, SEVERITY_NAME_TOOL as SEVERITY_NAME } from '../Utils';

/* Son propiedades de contexto que se podran modificar de forma pública */
export const detectOpenChanges = ({ setData, context }) => {
  try {
    window.nookInfoExchange.registerAsObserver(QUEUE_NAMES.OPEN_CONTEXT_CHANGES, 'openContextChanges', (data) => {
      // check if we receive an object
      if (data) {
        // check if this data contains keys with those keys
        const dataKeys = Object.keys(data);
        let allow2ChangeContext = true;
        dataKeys.forEach((dataKey) => {
          if (allow2ChangeContext && !PROPS_ALLOW_EDIT.includes(dataKey)) {
            allow2ChangeContext = false;
          }
        })

        if (allow2ChangeContext) {
          /* aditional behaviour depends on key  */
          if (dataKeys.includes('promoteLogin')) {
            const newPromoteLogin = data.promoteLogin;
            setPromoteLoginModal2False(newPromoteLogin);
          } else {
            setData({ ...data });
          }
        }
      }
    });
  } catch (err) {
    sendMessageToSondaTool({
      processName: 'detectOpenChanges',
      severidad: SEVERITY_NAME.SEVERAL,
      info: null,
      error: err,
      context,
    });
  }
};
