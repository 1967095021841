import { QUEUE_NAMES } from '@bit/gerenciatd.utils.context';
import { getSEO } from '@bit/gerenciatd.adabyron.customizer';
import { SEO_MOCK } from '../Constants';
import { sendMessageToSondaTool, SEVERITY_NAME_TOOL as SEVERITY_NAME } from '../Utils';
/**
 * Obtiene los parámetros necesarios de la info de SEO y Página(taxonomía)
 * @returns {void}
 */
export const getPageInfo = ({ setData, context }) => {
  try {
    // listen SEO FROM PAGE
    window.nookInfoExchange.registerAsObserver(QUEUE_NAMES.SEO_PARAMS, 'mySeoObserver', (reqpageData) => {
      const totalReqData = {
        ...reqpageData,
        context,
      }
      getSEO(totalReqData, 'CTX').then((res) => {
        let pageDataResponse = res;
        if (!pageDataResponse) {
          sendMessageToSondaTool({
            processName: 'getSEO',
            severidad: SEVERITY_NAME.ERROR,
            info: '[SEO] is mocked',
            context,
          });
          pageDataResponse = SEO_MOCK;
        }
        setData({
          clientIDs: window.IC.getClientIds(),
          pageData: pageDataResponse,
          contextSeoMeta: pageDataResponse.description ? pageDataResponse.description : null,
          pageName: pageDataResponse.page_name ? pageDataResponse.page_name : null,
          pageProductName: pageDataResponse.product_name ? pageDataResponse.product_name : null,
          pageProductCode: pageDataResponse.page_product_code ? pageDataResponse.page_product_code : null,
          pageInfoReady: true,
          eventType: 'loaded',
        });
        window.nookInfoExchange.sendMessage(QUEUE_NAMES.SEO_DATA, pageDataResponse);
        window.nookInfoExchange.cleanObserver(QUEUE_NAMES.SEO_DATA, 'mySeoObserver');
      })
      
    });
  } catch (err) {
    sendMessageToSondaTool({
      processName: 'getPageInfo',
      severidad: SEVERITY_NAME.SEVERAL,
      info: null,
      error: err,
      context,
    });
  }
};

/**
 * Obtiene y setea en contexto los datos del test AB
 * @returns {void}
 */
export const getTestABInfo = (setData) => {
  window.nookInfoExchange.registerAsObserver(QUEUE_NAMES.CUSTO_TEST_VERSION, 'Context-Testing', (testData) => {
    window.nookInfoExchange.cleanObserver(QUEUE_NAMES.CUSTO_TEST_VERSION, 'Context-Testing');
    setData({
      clientIDs: window.IC.getClientIds(),
      ...testData,
    });
  });
}
