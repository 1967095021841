import merge from 'deepmerge';
import {
  getCookie,
  getDeviceKindDependsOnViewPort,
  setCookie,
  getUrlParams,
} from '@bit/gerenciatd.utils.common';
import { findOutEnv } from '@bit/gerenciatd.utils.environment';
import { getSegment, QUEUE_NAMES } from '@bit/gerenciatd.utils.context';
import { getInfoContextFromCCLI } from './requests/ccli';
import { getPageInfo, getTestABInfo } from './subscriptions/pageInfo';
import { detectOpenChanges } from './subscriptions/openProps';
import {
  getTabId,
  isThereAnyKeyWithChanges,
  sendMessageToSondaTool,
  SEVERITY_NAME_TOOL as SEVERITY_NAME,
  calculateNetworkParams,
  getMockedContext,
  isGestor,
  isLocalHost,
  isQaEnv,
  checkSegment,
  redirectIE2NotSuported,
} from './Utils';
import {
  PAGE_VIEW_KEYS,
  EVENT_TYPES,
  CUSTO_PARAMS,
  CUSTO_TEST_VERSION,
  GA_RETRIES,
} from './Constants';

/**
 * Implementa la clase Context
 * @link {https://tecnologiadigital-col.atlassian.net/wiki/spaces/TD/pages/1838153729/Context}
 */
class Context {
  constructor() {
    // Estructura privada con la que va a trabajar el contexto.
    let data = {};
    const privateData = {
      custoParamsActualization: false,
      pageParamsActualization: false,
      globalParamsActualization: false,
      custoTestVersionActualization: false,
    }
    /**
     * Devuelve si estamos en webview de novum o no.
     * @returns {boolean} Null en caso de no poder saberlo.
     */
    const isNovum = (context) => {
      try {
        return sessionStorage.getItem('canalVenta') === 'appNovum';
      } catch (err) {
        sendMessageToSondaTool({
          processName: 'isNovum',
          severidad: SEVERITY_NAME.WARNING,
          info: null,
          error: err,
          context,
        });
        return null;
      }
    }

    
    /**
     * Envía un mensaje a nookInfo con los datos del contexto actualizados.
     * @returns {void}
     */
    const sendActualizationMessage = () => {
      if (privateData.globalParamsActualization) {
        window.nookInfoExchange.sendMessage(QUEUE_NAMES.GLOBAL, data);
      }
      if (privateData.custoParamsActualization && (data.clientIDs.sessId || checkSegment() === 'empresas' || isLocalHost() || isQaEnv())) {
        window.nookInfoExchange.sendMessage(QUEUE_NAMES.CUSTO, data);
        privateData.custoParamsActualization = false;
      }
      if (privateData.pageParamsActualization) {
        window.nookInfoExchange.sendMessage(QUEUE_NAMES.PAGE, data);
        privateData.pageParamsActualization = false;
      }
      if (privateData.custoTestVersionActualization) {
        const { testVersion, testName, testDisable } = data;
        window.nookInfoExchange.sendMessage(QUEUE_NAMES.CUSTO_TEST_VERSION_RESULT, {
          testDisable,
          testVersion,
          testName,
        });
        privateData.custoTestVersionActualization = false;
      }
    }

    /**
     * Actualiza los datos del contexto y avisa en la cola de mensajes
     * @param {object} object Estructura de datos utilizados por el contexto
     * @returns {void}
     */
    const setData = (object) => {
      let mergedObject = object;
      if (data.environment && data.environment.name) {
        const envName = data.environment.name;
        mergedObject = merge.all([object, getMockedContext(envName) || {}]);
      }
      if (isThereAnyKeyWithChanges(mergedObject, CUSTO_PARAMS, data)) {
        privateData.custoParamsActualization = true;
      }
      if (isThereAnyKeyWithChanges(mergedObject, PAGE_VIEW_KEYS, data)) {
        privateData.pageParamsActualization = true;
      }
      if (privateData.pageParamsActualization
        || privateData.custoParamsActualization
        || isThereAnyKeyWithChanges(mergedObject, Object.keys(data), data)) {
        privateData.globalParamsActualization = true;
      }
      if (isThereAnyKeyWithChanges(mergedObject, CUSTO_TEST_VERSION, data)) {
        privateData.custoTestVersionActualization = true;
      }

      const newData = {
        ...data,
        ...mergedObject,
      }
      data = newData;
      sendActualizationMessage();
    }

    /**
     * Recupera los datos del contexto guardados en cache o un dato concreto
     * del contexto pasando su key.
     * @param {number} key (opcional) clave del dato a buscar
     * @returns {object} Objeto con la cache del contexto (null si falla)
     
    const getCache = (key) => {
      try {
        const contextString = sessionStorage.getItem('cmsContext');
        const contextObject = JSON.parse(contextString);
        if (!key) {
          return contextObject;
        }
        return contextObject[key];
      } catch (err) {
        return null;
      }
    }
*/
    /**
     * Actualiza los datos del contexto en la caché de context para persistir datos.
     * @param {object} object Estructura de datos utilizados por el contexto
     * @returns {void}
    const setCache = (object) => {
      const previusContext = getCache();
      if (previusContext) {
        const newContext = {
          ...previusContext,
          ...object,
        };
        sessionStorage.setItem('cmsContext', JSON.stringify(newContext));
      } else {
        sessionStorage.setItem('cmsContext', JSON.stringify(object));
      }
    }
    */


    /**
     * Setea la cookie de segmento con una caducidad de 30 días.
     */
    const setSegmentCookie = (segment) => {
      try {
        if (segment) {
          setCookie('SEG_NAV', segment, 30);
        }

        // fixing errors
      } catch (e) {
        sendMessageToSondaTool({
          processName: 'setSegmentCookie',
          severidad: SEVERITY_NAME.ERROR,
          info: null,
          error: e,
        });
      }
    }

    /**
     * Inicializa los datos en contexto que pueden recogerse al aterrizar en página.
     * @returns {void}
     */
    function initializeData() {
      let initialContextData = {}
      try {
        const timeStamp = Date.now();
        const pageSegment = getSegment();
        setSegmentCookie(pageSegment);
        // Creating first context structure
        // Seteamos la primera versión de la estructura.
        initialContextData = {
          numLocationChanges: 0,
          infoRecom: null,
          eventType: EVENT_TYPES.VIEW,
          servicesReady: {},
          tabId: getTabId(),
          pageWebCode: null,
          pageName: null,
          pageWebName: null,
          pageProductCode: null,
          pageProductName: null,
          clientIDs: window.IC.getClientIds(),
          userAgent: window.navigator.userAgent,
          userType: '',
          infoContextApiFailed: false,
          managerInfo: null,
          userConsentCookieOneTrust: null,
          pageSegment,
          contextURL: window.location.href,
          subseg: false,
          userPriority: false,
          gwIsp: getCookie('gwIsp'),
          gwIspI3D: getCookie('gwIsp_i3_d'),
          device: getDeviceKindDependsOnViewPort(),
          contextSeoMeta: null,
          i3Ready: timeStamp,
          pageInfoReady: false,
          referalAP: (document && document.referrer) || '',
          environment: findOutEnv('movistar.es'),
          testVersion: null,
          testName: null,
          testDisable: null,
          networkParams: { ...calculateNetworkParams() },
          version: VERSION_JS || 'unknown',
          promoteLogin: {
            showModal: false,
            showSpeechBubble: false,
          },
        };
        /* añadimos el valor de isNovum con lo que tengamos de context,
         para que las sondas tengan mas información */
        initialContextData.isWebView = isNovum(initialContextData);

        // setData(contextData);
        return initialContextData;
      } catch (err) {
        sendMessageToSondaTool({
          processName: 'initializeData',
          severidad: SEVERITY_NAME.FATAL,
          info: null,
          error: err,
        });
        return initialContextData;
      }
    }

    // +++++++++++++ Funciones de propia lógica de Context ++++++++++++++
    /**
     * Se subscribe a los eventos de cambio de ruta en la spa.
     * @returns {void}
     */
    const subscribeToRouteChanges = () => {
      try {
        window.nookInfoExchange.registerAsObserver('SPA-Location', 'Context-Route', () => {
          sendMessageToSondaTool({
            context: this.data,
            processName: 'subscribeToRouteChanges',
            severidad: SEVERITY_NAME.INFO,
            info: 'Se ha detectado un cambio de ruta en la SPA.',
          });

          // si soy gestor y no tengo la info
          if (isGestor() && data && !data.managerInfo) {
            getInfoContextFromCCLI(
              setData,
              data,
              null,
            );
          }

          setData({
            clientIDs: window.IC.getClientIds(),
            contextURL: window.location.href,
            pageInfoReady: false,
            numLocationChanges: data.numLocationChanges + 1,
            testVersion: null,
            testName: null,
          });
        });
      } catch (err) {
        sendMessageToSondaTool({
          context: this.data,
          processName: 'subscribeToRouteChanges',
          severidad: SEVERITY_NAME.ERROR,
          info: null,
          error: err,
        });
      }
    }
    /**
     * Método init de la aplicación
     * @returns {void}
     */
    this.init = () => {
      try {
        // gestion para IE
        redirectIE2NotSuported();
        /* -------------------------------------------------------------------------- */
        /*                              Regular behaviour                             */
        /* -------------------------------------------------------------------------- */
        if (!window.contextLoaded) {
          let gaRetries = GA_RETRIES;
          let mcsessionRetries = GA_RETRIES;
          this.gaInterval = setInterval(() => {
            let clientIds = window.IC.getClientIds();
            if ((clientIds.ga) || gaRetries <= 1) {
              clearInterval(this.gaInterval);

              const dependentActions = async () => {
                // un interval con clientIds
                this.mantixInterval = setInterval(() => {
                  clientIds = window.IC.getClientIds();
                  if (mcsessionRetries <= 0 || (clientIds && clientIds.sessId)) {
                    setData({
                      eventType: 'loaded',
                    })
                    clearInterval(this.mantixInterval);
                    getPageInfo({ setData, context: data });
                    subscribeToRouteChanges();
                    getTestABInfo(setData);
                    detectOpenChanges({ setData, context: data });
                  }
                  window.contextLoaded = true;
                  mcsessionRetries -= 1;
                }, 100)
              }

              const initialData = initializeData();
              getInfoContextFromCCLI(
                setData,
                initialData,
                dependentActions,
              );
            }
            gaRetries -= 1;
          }, 100);
        } else {
          sendMessageToSondaTool({
            processName: 'init',
            severidad: SEVERITY_NAME.INFO,
            info: 'CUIDADO, SE ESTÁ INCLUYENDO CONTEXTO DE FORMA REDUNDANTE',
          });
        }
      } catch (err) {
        sendMessageToSondaTool({
          processName: 'init',
          severidad: SEVERITY_NAME.FATAL,
          info: null,
          error: err,
        });
      }
    }
  }
}

export default Context;
