import merge from 'deepmerge';
// This will come from @bit
import { getMyData, getMyDataType } from '@bit/gerenciatd.adabyron.client';
import { getInfo } from '@bit/gerenciatd.adabyron.manager';
// import { getInfoRecommendation } from '../infoRecom';
import { getI3Info } from '../i3';
import {
  sendMessageToSondaTool,
  SEVERITY_NAME_TOOL as SEVERITY_NAME,
  isGestor,
  composeI3Object,
  mergeIp3gHashes,
  checkTcolApiAllowed,
  getPromoteLoginState,
  getMockedContext,
} from '../../Utils';
import {
  extractFromMyDataToContext,
  extractFromMyDataKindToContext,
  extractFromGetInfoManager,
} from './tools';
import { getInfoContext, DEFAULT_RESPONSE_ON_ERROR, normalizeUserName } from '../../ccli';

export const isTelcoSite = () => {
  return window.location.href.indexOf('cliente/areaprivada/#') >= 0;
}

export const isTelcoBuyProcess = () => {
  return window.location.href.indexOf('/particulares/Aplicaciones/Telco/procesoCompra') >= 0;
}

const setDataOnContext = async (setData, data, initialData, onDone) => {
  /* Nos viene de info contexto: variables de context y xCol4Hashes */
  const {
    xCol4Hashes,
    contextVars,
  } = data;

  const finalContextVars = {
    ...contextVars,
    ...xCol4Hashes,
  }

  // LOGICA DE MODALES Y BURBUJA
  // Si el usuario no está logado calculamos la promoción del login
  if (
    !finalContextVars.userCUCFijoHash
    && !finalContextVars.userCUCMovilHash
    && !finalContextVars.userCUCPrepagoHash
    && !finalContextVars.userIdPartyHash
    && finalContextVars.userType !== 'Gestor') {
    const { pageSegment, contextURL } = initialData;
    const promoteLoginValue = getPromoteLoginState({ pageSegment, contextURL });
    finalContextVars.promoteLogin = promoteLoginValue;
  }

  setData({
    ...initialData,
    ...finalContextVars,
  });

  if (onDone) {
    onDone();
  }
}

async function getHashesFromIC({ hashes, context }) {
  const ICip3gHashes = await getI3Info({ context });
  return mergeIp3gHashes(ICip3gHashes, hashes);
}

export async function getInfoContextFromCCLI(setData, initialData, onDone) {
  const envName = initialData.environment.name;
  const initialDataMergedWithMockedInfo = merge.all([initialData, getMockedContext(envName) || {}]);
  if (!isGestor()) {
    getInfoContext(initialDataMergedWithMockedInfo)
      .then(async (data) => {
        const { xCol4Hashes, contextVars } = data;
        const result = {
          xCol4Hashes: null,
          contextVars,
        };
        const xCol4HashesFromApi = composeI3Object(xCol4Hashes);
        // aqui los vamos a tener calculados con IC
        result.xCol4Hashes = await getHashesFromIC({
          context: initialDataMergedWithMockedInfo,
          hashes: xCol4HashesFromApi,
        });
        // miramos el getInfoRecom que depende de esto
        // const { infoRecom, pageWebCode } = await getInfoRecommendation({
        //   context: initialDataMergedWithMockedInfo,
        // });

        result.contextVars = {
          ...result.contextVars,
          // infoRecom,
          // pageWebCode,
        }

        /* -------------------------------------------------------------------------- */
        /*                         Enriquecimiento de la xCOL con servicioI3          */
        /* -------------------------------------------------------------------------- */

        // Enriquecemos cuando no tenemos idparty
        // compruebo la c
        if ( // Este if es solo para enriquecimiento de la XCOL en Logado Telco
          result
          && result.contextVars
          && result.contextVars.userType === 'Telco'
          && !result.xCol4Hashes.userIdPartyHash
          && checkTcolApiAllowed()) {
          const initialDataChanged = {
            ...initialDataMergedWithMockedInfo,
            ...result.contextVars,
            userType: 'Telco',
          }
          // Rwalizamos la promise para obtener más info
          getMyData(initialDataChanged, 'TC', false).then(({ xcol }) => {
            const hashesFromMisDatos = composeI3Object(xcol)
            // Redefinimos para cuando obtengo xcol de misDAtos
            const reResult = {
              ...result,
              xCol4Hashes: {
                ...result.xCol4Hashes,
                ...hashesFromMisDatos,
              },
            }
            setDataOnContext(setData, reResult, initialDataMergedWithMockedInfo, onDone);
          }).catch(() => {
            setDataOnContext(setData, result, initialDataMergedWithMockedInfo, onDone);
          });
        } else {
          // si no hay que enriquecer con mis DAtos caigo aki
          // Por este else pueden entrar no logados/legado/telco sin necesidad de enriquecer
          result.contextVars = merge.all([result.contextVars, getMockedContext(envName) || {}]);
          setDataOnContext(setData, result, initialDataMergedWithMockedInfo, onDone);
        }
      })
      .catch(() => {
        // PROCESO CUANDO CUANDO INFO CONTEXT DA ERROR
        const response = DEFAULT_RESPONSE_ON_ERROR;
        response.contextVars.userType = (isTelcoBuyProcess() || isTelcoSite()) ? 'Telco' : '';
        response.contextVars.infoContextApiFailed = true;
        setDataOnContext(setData, response, initialDataMergedWithMockedInfo, onDone);
        sendMessageToSondaTool({
          processName: 'request/cli/getInfoContextFromCCLI',
          severidad: SEVERITY_NAME.ERROR,
          info: 'El api infocontexto ha fallado',
          context: initialDataMergedWithMockedInfo,
        });
      });
  }

  // LÓGICA PARA GESTORES
  if (isGestor()) {
    // actualizamos el contexto para que las promises funcionen debidamente
    const newContext = {
      ...initialDataMergedWithMockedInfo,
      userType: 'Gestor',
    }

    const promiseA = getMyData(newContext, 'VT', false);
    const promiseB = getInfo(newContext);
    const promiseC = getMyDataType(newContext);
    const promises = [promiseA, promiseB, promiseC]
    // objeto que mutaremos para actualizar info o tener caso base en caso de error
    let contextData2Add = {
      ...DEFAULT_RESPONSE_ON_ERROR,
    };
    // le seteo que se trata de un Gestor
    contextData2Add.contextVars.userType = 'Gestor';

    Promise.allSettled(promises).then((data) => {
      const getMyDataInfo = data && data[0];
      const getInfoGestor = data && data[1];
      const getMyDataTypeInfo = data && data[2];
      // normalizo y añado información de misDatos
      if (getMyDataInfo && getMyDataInfo.value) {
        contextData2Add = extractFromMyDataToContext(contextData2Add, getMyDataInfo.value, normalizeUserName);
      }
      // normalizo y añado información sobre datos del gestor
      if (getInfoGestor && getInfoGestor.value) {
        contextData2Add = extractFromGetInfoManager(contextData2Add, getInfoGestor.value);
      }
      // normalizo y añado información api misdatos/otros
      if (getMyDataTypeInfo && getMyDataTypeInfo.value) {
        contextData2Add = extractFromMyDataKindToContext(contextData2Add, getMyDataTypeInfo.value);
      }
      // invoco a la función principal para setear estos datos
      setDataOnContext(setData, contextData2Add, initialDataMergedWithMockedInfo, onDone);
    }).catch((err) => {
      sendMessageToSondaTool({
        processName: 'request/cli/getInfoContextFromCCLI',
        severidad: SEVERITY_NAME.ERROR,
        info: 'Error al normalizar información de gestores',
        err,
        context: initialDataMergedWithMockedInfo,
      });
      // invoco a la función principal para setear estos datos
      setDataOnContext(setData, contextData2Add, initialDataMergedWithMockedInfo, onDone);
    })
  }
}
