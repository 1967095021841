/**
 * @param {*} currentContextInfo Información actual de contexto
 * @param {*} getMyDataInfo Información recibida del api getMyData
 * @param {*} normalizeUserName Función para normalizar el nombre de usuario
 * @returns Prepara la estructura para actualizar contexto
 */
export const extractFromMyDataToContext = (currentContextInfo, getMyDataInfo, normalizeUserName) => {
  const normalizedResponse = { ...currentContextInfo };
  // usuario normal
  if (
    getMyDataInfo
    && getMyDataInfo.name
    && getMyDataInfo.firstSurname
  ) {
    const { name, firstSurname, secondSurname } = getMyDataInfo;
    let apellidos = `${firstSurname}`;
    if (secondSurname) {
      apellidos = `${apellidos} ${secondSurname}`;
    }
    normalizedResponse.contextVars.userName = normalizeUserName(name, apellidos);
  }
  // empresa
  if (
    getMyDataInfo
    && getMyDataInfo.companyName
  ) {
    normalizedResponse.contextVars.userName = getMyDataInfo.companyName;
  }

  return normalizedResponse;
}

/**
 * @param {*} currentContextInfo Información actual de contexto
 * @param {*} getMyDataTypeInfo Información del api de getMyDataType
 * @returns Prepara la estructura para actualizar contexto
 */
export const extractFromMyDataKindToContext = (currentContextInfo, getMyDataTypeInfo) => {
  const normalizedResponse = {
    ...currentContextInfo,
  }

  if (getMyDataTypeInfo && getMyDataTypeInfo.priority) {
    normalizedResponse.contextVars.userPriority = getMyDataTypeInfo.priority;
  }

  return normalizedResponse;
}
/**
 * @param {*} currentContextInfo Información actual de contexto
 * @param {*} getInfoManagerData Información recibida del api getInfoManager
 * @returns Prepara la estructura para actualizar contexto
 */
export const extractFromGetInfoManager = (currentContextInfo, getInfoManagerData) => {
  const normalizedResponse = {
    ...currentContextInfo,
  }
  if (
    getInfoManagerData
    && getInfoManagerData.roleCode
    && getInfoManagerData.name
    && getInfoManagerData.shopName
  ) {
    const { roleCode, name, shopName } = getInfoManagerData;
    normalizedResponse.contextVars.managerInfo = {
      name,
      roleCode,
      shopName,
    }
  }

  return normalizedResponse;
};
